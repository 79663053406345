import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/consultants";

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getSingle(consultantId) {
        return axios.get(apiUrl + "/" + consultantId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    create(consultantData) {
        return axios.post(apiUrl, {
            userId:         consultantData.userId,
            headline:       consultantData.headline,
            teaser:         consultantData.teaser,
            tags:           consultantData.tags,
            description:    consultantData.description,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    update(consultantId, consultantData) {
        return axios.put(apiUrl + "/" + consultantId, {
            userId:         consultantData.userId,
            headline:       consultantData.headline,
            teaser:         consultantData.teaser,
            tags:           consultantData.tags,
            description:    consultantData.description,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    delete(consultantId) {
        return axios.delete(apiUrl + "/" + consultantId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    }

}