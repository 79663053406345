const state = {
    items: []
};

const getters = {

}

const actions = {

}

const mutations = {

    addObject(state, payload) {
        state.items.push({
            message: payload.message,
            color: payload.color
        });
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}