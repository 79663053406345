import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/pages";

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getSingle(pageId) {
        return axios.get(apiUrl + "/" + pageId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    create(pageData) {
        return axios.post(apiUrl, {
            title:          pageData.title,
            slug:           pageData.slug,
            type:           pageData.type,
            template:       pageData.template,
            show_in_nav:    pageData.show_in_nav,
            sort:           pageData.sort,
            level:          pageData.level,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    update(pageId, pageData) {
        return axios.put(apiUrl + "/" + pageId, {
            title:          pageData.title,
            slug:           pageData.slug,
            type:           pageData.type,
            template:       pageData.template,
            show_in_nav:    pageData.show_in_nav,
            sort:           pageData.sort,
            level:          pageData.level,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    delete(pageId) {
        return axios.delete(apiUrl + "/" + pageId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    }

}