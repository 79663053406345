<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista wpisów</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large color="primary" class="elevation-0" to="/posts/create" :disabled="loadingPosts">Dodaj wpis</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="postsList"
            :loading="loadingPosts"
            :search="search"
            class="table--datatable elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" elevation="0" class="ma-1" @click="showPostDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <router-view name="postDialog" :postData="postData" v-on:close-dialog="closePostDialog" v-on:dialog-callback="getPosts"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deletePost"></router-view>
  </v-container>
</template>

<script>
  import postService from '../services/post.service';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Posts',

    data: () => ({
      postsList: [],
      search: '',
      headers: [
        { text: 'Tytuł', value: 'title' },
        { text: 'Autor', value: 'user_fullname' },
        { text: 'Data', value: 'date' },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingPosts: true,
      postData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getPosts();
    },

    methods: {
      getPosts() {
        this.loadingPosts = true;

        postService.getList().then(data => {
          if (data.success) {
            this.postsList = data.data.posts;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPosts = false;
        });
      },

      showPostDialog(post) {
        this.postData = post;
        this.$router.push({ name: 'postEdit', params: { postId: post.id } });
      },

      closePostDialog() {
        this.postData = null;
      },

      showDeleteDialog(post) {
        this.deleteDialogData = {
          postId: post.id,
          title: 'Usuwanie wpisu',
          text: 'Na pewno usunąć wpis <strong>' + post.title + '</strong>?'
        };
        this.$router.push({ name: 'postDelete', params: { postId: post.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'posts' })
      },

      deletePost() {
        this.loadingDeleteDialog = true;

        postService.delete(this.deleteDialogData.postId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getPosts();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },
    }
  }
</script>