<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista konsultantów</h1>
        </v-col>
        <v-col cols="auto">
<!--          <v-btn large color="primary" class="elevation-0" to="/consultants/create" :disabled="loadingConsultants">Nowy konsultant</v-btn>-->
          <v-btn large color="primary" class="elevation-0" @click="goToConsultantForm(null)" :disabled="loadingConsultants">Nowy konsultant</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="consultantsList"
            :sort-desc="false"
            :loading="loadingConsultants"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" elevation="0" class="ma-1" @click="goToConsultantForm(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteConsultant"></router-view>
  </v-container>
</template>

<script>
  import consultantService from '../services/consultant.service';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Consultants',

    data: () => ({
      consultantsList: [],
      search: '',
      headers: [
        { text: 'Imię i nazwisko', value: 'userFullname' },
        { text: 'E-mail', value: 'userEmail' },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingConsultants: true,
      consultantData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getConsultants();
    },

    methods: {
      getConsultants() {
        this.loadingConsultants = true;

        consultantService.getList().then(data => {
          if (data.success) {
            this.consultantsList = data.data.consultants;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingConsultants = false;
        });
      },

      goToConsultantForm(consultant) {
        if (consultant) {
          this.consultantData = consultant;
          this.$router.push({ name: 'consultantEdit', params: { consultantId: consultant.id } });
        } else {
          this.$router.push({ name: 'consultantCreate' });
        }
      },

      showDeleteDialog(consultant) {
        this.deleteDialogData = {
          consultantId: consultant.id,
          title: 'Usuwanie konsultanta',
          text: 'Na pewno usunąć konsultanta <strong>' + consultant.userName + ' ' + consultant.userSurname + '</strong>?'
        };
        this.$router.push({ name: 'consultantDelete', params: { consultantId: consultant.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'consultants' })
      },

      deleteConsultant() {
        this.loadingDeleteDialog = true;

        consultantService.delete(this.deleteDialogData.consultantId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getConsultants();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      }
    }
  }
</script>