import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/users";

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getSingle(userId) {
        return axios.get(apiUrl + "/" + userId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    create(userData) {
        return axios.post(apiUrl, {
            name:               userData.name,
            surname:            userData.surname,
            email:              userData.email,
            password:           userData.password,
            role:               userData.role,
            isActive:           userData.isActive,
            agreementMarketing: userData.agreementMarketing,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    update(userId, userData) {
        return axios.put(apiUrl + "/" + userId, {
            name:               userData.name,
            surname:            userData.surname,
            email:              userData.email,
            password:           userData.password,
            role:               userData.role,
            isActive:           userData.isActive,
            agreementMarketing: userData.agreementMarketing,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    delete(userId) {
        return axios.delete(apiUrl + "/" + userId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getConsultants() {
        return axios.get(apiUrl + '/consultants', { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getNonConsultants() {
        return axios.get(apiUrl + '/non-consultants', { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    }

}