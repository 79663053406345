import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/consultants/tags";

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getSingle(consultantTagId) {
        return axios.get(apiUrl + "/" + consultantTagId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    create(consultantTagData) {
        return axios.post(apiUrl, {
            name: consultantTagData.name,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    update(consultantTagId, consultantTagData) {
        return axios.put(apiUrl + "/" + consultantTagId, {
            name: consultantTagData.name,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    delete(consultantTagId) {
        return axios.delete(apiUrl + "/" + consultantTagId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    }

}