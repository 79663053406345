<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Tag</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.name"
                  :disabled="loadingForm"
                  :error-messages="formNameErrors"
                  label="Nazwa"
                  hide-details="auto"
                  outlined
                  @input="$v.form.name.$touch(), removeApiError('name')"
                  @blur="$v.form.name.$touch(), removeApiError('name')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">
            <span v-if="$route.params.consultantTagId">Zapisz zmiany</span>
            <span v-else>Dodaj tag</span>
          </v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingConsultantTag" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import consultantTagService from '../services/consultantTag.service';

  export default {
    name: 'ConsultantTagDialog',

    props: ['consultantTagData'],

    mixins: [validationMixin],

    validations: {
      form: {
        name: {
          required
        },
      }
    },

    data: () => ({
      dialog: true,
      form: {
        name: '',
      },
      loadingConsultantTag: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formNameErrors() {
        const errors = [];
        if (!this.$v.form.name.$dirty) return errors;
        !this.$v.form.name.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.name) errors.push(this.apiErrors.name);
        return errors;
      },
    },

    created() {
      const consultantTagId = this.$route.params.consultantTagId
      if (consultantTagId) {
        if (this.consultantTagData) {
          this.form.name = this.consultantTagData.name;
        } else {
          this.loadingConsultantTag = true;
          this.getConsultantTag(consultantTagId);
        }
      }
    },

    methods: {
      getConsultantTag(consultantTagId) {
        consultantTagService.getSingle(consultantTagId).then(data => {
          if (data.success) {
            this.form.name = data.data.consultantTag.name;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingConsultantTag = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.consultantTagId) {
            this.updateConsultantTag();
          } else {
            this.createConsultantTag();
          }
        }
      },

      createConsultantTag() {
        consultantTagService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateConsultantTag() {
        const consultantTagId = this.$route.params.consultantTagId;
        consultantTagService.update(consultantTagId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'consultantTags' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>