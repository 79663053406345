<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="7" md="6" lg="5" xl="4">
        <form @submit.prevent="submitForm">
          <v-card elevation="0">
            <v-card-text class="px-6 pt-6 pb-0">
              <v-row>
                <v-col cols="12">
                  <v-img :src="require('../assets/logo.svg')" max-width="150" class="mx-auto mb-2" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="form.email"
                      :disabled="loadingForm"
                      :error-messages="formEmailErrors"
                      label="E-mail"
                      hide-details="auto"
                      outlined
                      @input="$v.form.email.$touch(), removeApiError('email')"
                      @blur="$v.form.email.$touch(), removeApiError('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :disabled="loadingForm"
                      :error-messages="formPasswordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      hide-details="auto"
                      outlined
                      @input="$v.form.password.$touch(), removeApiError('password')"
                      @blur="$v.form.password.$touch(), removeApiError('password')"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pa-6">
              <v-spacer></v-spacer>
              <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">Login</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required, email } from "vuelidate/lib/validators";

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",

    mixins: [validationMixin],

    validations: {
      form: {
        email: {
          required,
          email
        },
        password: {
          required
        }
      }
    },

    data: () => ({
      form: {
        email: "",
        password: ""
      },
      showPassword: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      user() {
        return this.$store.state.account.user;
      },

      formEmailErrors() {
        const errors = [];
        if (!this.$v.form.email.$dirty) return errors;
        !this.$v.form.email.required && errors.push("Musisz uzupełnić to pole");
        !this.$v.form.email.email && errors.push("Adres e-mail jest nieprawidłowy");
        if (this.apiErrors && this.apiErrors.email) errors.push(this.apiErrors.email);
        return errors;
      },

      formPasswordErrors() {
        const errors = [];
        if (!this.$v.form.password.$dirty) return errors;
        !this.$v.form.password.required && errors.push("Musisz uzupełnić to pole");
        if (this.apiErrors && this.apiErrors.password) errors.push(this.apiErrors.password);
        return errors;
      }
    },

    mounted() {
      if (this.user) {
        this.$router.push({ name: "dashboard" });
      }
    },

    methods: {
      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;
          this.login();
        }
      },

      login() {
        this.$store.dispatch("account/login", this.form).then(
            data => {
              this.loadingForm = false;

              if (data.success) {
                this.$router.push({ name: "dashboard" });
              } else {
                if (data.status == 401 || data.status == 403) {
                  this.$store.dispatch("account/logout");
                  this.$router.push({ name: "login" });
                }

                this.apiErrors = data.errors;
              }

              if (data.snackbar) {
                this.$store.commit("snackbar/addObject", {
                  message: data.message,
                  color: data.snackbar.color
                });
              }
            }
        )
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  };
</script>