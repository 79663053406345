<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Kategoria</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.name"
                  :disabled="loadingForm"
                  :error-messages="formNameErrors"
                  label="Nazwa"
                  hide-details="auto"
                  outlined
                  @input="$v.form.name.$touch(), removeApiError('name')"
                  @blur="$v.form.name.$touch(), removeApiError('name')"
                  @change="setSlug()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.slug"
                  :disabled="loadingForm"
                  :error-messages="formSlugErrors"
                  label="Slug"
                  hint="Jeśli pole jest puste zostanie ono automatycznie uzupełnione po wprowadzeniu nazwy"
                  persistent-hint
                  hide-details="auto"
                  outlined
                  @input="$v.form.slug.$touch(), removeApiError('slug')"
                  @blur="$v.form.slug.$touch(), removeApiError('slug')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingCategory" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import categoryService from '../services/category.service';
  import {slugify} from "@/helpers";

  export default {
    name: 'CategoryDialog',

    props: ['categoryData'],

    mixins: [validationMixin],

    validations: {
      form: {
        name: {
          required
        },
        slug: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        name: '',
        slug: ''
      },
      loadingCategory: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formNameErrors() {
        const errors = [];
        if (!this.$v.form.name.$dirty) return errors;
        !this.$v.form.name.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.name) errors.push(this.apiErrors.name);
        return errors;
      },
      formSlugErrors() {
        const errors = [];
        if (!this.$v.form.slug.$dirty) return errors;
        !this.$v.form.slug.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.slug) errors.push(this.apiErrors.slug);
        return errors;
      },
    },

    created() {
      const categoryId = this.$route.params.categoryId;
      if (categoryId) {
        if (this.categoryData) {
          this.form.name = this.categoryData.name;
          this.form.slug = this.categoryData.slug;
        } else {
          this.loadingCategory = true;
          this.getCategory(categoryId);
        }
      }
    },

    methods: {
      getCategory(categoryId) {
        categoryService.getSingle(categoryId).then(data => {
          if (data.success) {
            this.form.name = data.data.category.name;
            this.form.slug = data.data.category.slug;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingCategory = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.categoryId) {
            this.updateCategory();
          } else {
            this.createCategory();
          }
        }
      },

      createCategory() {
        categoryService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateCategory() {
        const categoryId = this.$route.params.categoryId;
        categoryService.update(categoryId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'categories' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      },

      setSlug() {
        if (this.form.slug == '') {
          this.form.slug = slugify(this.form.name);
        }
      }
    }
  }
</script>