<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista stron</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large color="primary" class="elevation-0" to="/pages/create" :disabled="loadingPages">Dodaj stronę</v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="d-flex justify-center align-center py-4" v-if="loadingPages">
      <v-progress-circular
          indeterminate
          color="primary"
          size="46"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div v-for="page in pagesList" :key="page.id" :class="{ 'pl-8': page.level > 1 }">
        <v-card elevation="0" class="item pa-3 mb-2">
          <div class="d-flex align-center">
            <v-icon small color="green" class="mr-3" v-if="page.show_in_nav == 1">mdi-eye</v-icon><v-icon small color="red" class="mr-3" v-else>mdi-eye-off</v-icon>
            <p class="my-0"><router-link :to="{ name: 'pageDetails', params: { pageId: page.id, pageData: page } }">{{ page.title }}</router-link></p>
            <div class="ml-auto">
              <v-btn color="primary" elevation="0" class="ma-1" @click="showPageDialog(page)">Edytuj</v-btn>
              <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(page)" :disabled="page.id == 1">Usuń</v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <router-view name="pageDialog" :pageData="pageData" v-on:close-dialog="closePageDialog" v-on:dialog-callback="getPages"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deletePage"></router-view>
  </v-container>
</template>

<script>
  import pageService from '../services/page.service';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Pages',

    data: () => ({
      pagesList: [],
      loadingPages: true,
      pageData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false,
    }),

    created() {
      this.getPages();
    },

    methods: {
      getPages() {
        this.loadingPages = true;

        pageService.getList().then(data => {
          if (data.success) {
            this.pagesList = data.data.pages;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPages = false;
        });
      },

      showPageDialog(page) {
        this.pageData = page;
        this.$router.push({ name: 'pageEdit', params: { pageId: page.id } });
      },

      closePageDialog() {
        this.pageData = null;
      },

      showDeleteDialog(page) {
        this.deleteDialogData = {
          pageId: page.id,
          title: 'Usuwanie strony',
          text: 'Na pewno usunąć stronę <strong>' + page.title + '</strong>?'
        };
        this.$router.push({ name: 'pageDelete', params: { pageId: page.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'pages' })
      },

      deletePage() {
        this.loadingDeleteDialog = true;

        pageService.delete(this.deleteDialogData.pageId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getPages();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      }
    }
  }
</script>