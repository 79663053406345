<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista użytkowników</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large color="primary" class="elevation-0" to="/users/create" :disabled="loadingUsers">Nowy użytkownik</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="usersList"
            :sort-desc="false"
            :loading="loadingUsers"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
        >
          <template v-slot:[`item.fullname`]="{ item }">
            <router-link :to="{ name: 'pageDetails', params: { pageId: item.id, pageData: item } }">{{ item.fullname }}</router-link>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <v-chip label small :color="getColor(item.role)" text-color="white" class="text-uppercase ma-1">
              {{ getName(item.role) }}
            </v-chip>
            <v-chip label small color="green" text-color="white" class="text-uppercase ma-1" v-if="item.isConsultant == 1">
              Konsultant
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" elevation="0" class="ma-1" @click="showUserDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)" :disabled="item.id == 1">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    
    <router-view name="userDialog" :userData="userData" v-on:close-dialog="closeUserDialog" v-on:dialog-callback="getUsers"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteUser"></router-view>
  </v-container>
</template>

<script>
  import userService from '../services/user.service';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Users',

    data: () => ({
      usersList: [],
      search: '',
      headers: [
        { text: 'Imię i nazwisko', value: 'fullname' },
        { text: 'E-mail', value: 'email' },
        { text: 'Rola', value: 'role', sortable: false },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingUsers: true,
      userData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getUsers();
    },

    methods: {
      getUsers() {
        this.loadingUsers = true;

        userService.getList().then(data => {
          if (data.success) {
            this.usersList = data.data.users;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingUsers = false;
        });
      },

      showUserDialog(user) {
        this.userData = user;
        this.$router.push({ name: 'userEdit', params: { userId: user.id } });
      },

      closeUserDialog() {
        this.userData = null;
      },

      showDeleteDialog(user) {
        this.deleteDialogData = {
          userId: user.id,
          title: 'Usuwanie użytkownika',
          text: 'Na pewno usunąć użytkownika <strong>' + user.name + ' ' + user.surname + '</strong>?'
        };
        this.$router.push({ name: 'userDelete', params: { userId: user.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'users' })
      },

      deleteUser() {
        this.loadingDeleteDialog = true;

        userService.delete(this.deleteDialogData.userId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getUsers();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },

      getColor(role) {
        if (role == '2') return 'red'
        else return 'grey'
      },

      getName(role) {
        if (role == '2') return 'admin'
        else return 'użytkownik'
      },
    }
  }
</script>