import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/posts";

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    getSingle(postId) {
        return axios.get(apiUrl + "/" + postId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    create(postData) {
        return axios.post(apiUrl, {
            title:          postData.title,
            categories:     postData.categories,
            user_id:        postData.userId,
            date:           postData.date,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    update(postId, postData) {
        return axios.put(apiUrl + "/" + postId, {
            title:          postData.title,
            categories:     postData.categories,
            user_id:        postData.userId,
            date:           postData.date,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    },

    delete(postId) {
        return axios.delete(apiUrl + "/" + postId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "API connection error."
            };
            return response;
        });
    }

}