<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista tagów</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large color="primary" class="elevation-0" to="/consultants/tags/create" :disabled="loadingConsultantTags">Nowy tag</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="consultantTagsList"
            :sort-desc="false"
            :loading="loadingConsultantTags"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" elevation="0" class="ma-1" @click="showConsultantTagDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    
    <router-view name="consultantTagDialog" :userData="consultantTagData" v-on:close-dialog="closeConsultantTagDialog" v-on:dialog-callback="getConsultantTags"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteConsultantTag"></router-view>
  </v-container>
</template>

<script>
  import consultantTagService from '../services/consultantTag.service';

  export default {
    name: 'ConsultantTags',

    data: () => ({
      consultantTagsList: [],
      search: '',
      headers: [
        { text: 'Nazwa', value: 'name' },
        { text: 'Slug', value: 'slug' },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingConsultantTags: true,
      consultantTagData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getConsultantTags();
    },

    methods: {
      getConsultantTags() {
        this.loadingConsultantTags = true;

        consultantTagService.getList().then(data => {
          if (data.success) {
            this.consultantTagsList = data.data.consultantTags;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingConsultantTags = false;
        });
      },

      showConsultantTagDialog(consultantTag) {
        this.consultantTagData = consultantTag;
        this.$router.push({ name: 'consultantTagEdit', params: { consultantTagId: consultantTag.id } });
      },

      closeConsultantTagDialog() {
        this.consultantTagData = null;
      },

      showDeleteDialog(consultantTag) {
        this.deleteDialogData = {
          consultantTagId: consultantTag.id,
          title: 'Usuwanie tagu',
          text: 'Na pewno usunąć tag <strong>' + consultantTag.name + '</strong>?'
        };
        this.$router.push({ name: 'consultantTagDelete', params: { consultantTagId: consultantTag.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'consultantTags' })
      },

      deleteConsultantTag() {
        this.loadingDeleteDialog = true;

        consultantTagService.delete(this.deleteDialogData.consultantTagId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getConsultantTags();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },
    }
  }
</script>