<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Strona</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.title"
                  :disabled="loadingForm"
                  :error-messages="formTitleErrors"
                  label="Tytuł"
                  hide-details="auto"
                  outlined
                  @input="$v.form.title.$touch(), removeApiError('title')"
                  @blur="$v.form.title.$touch(), removeApiError('title')"
                  @change="setSlug()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.slug"
                  :disabled="loadingForm"
                  :error-messages="formSlugErrors"
                  label="Slug"
                  hint="Jeśli pole jest puste zostanie ono automatycznie uzupełnione po wprowadzeniu tytułu"
                  persistent-hint
                  hide-details="auto"
                  outlined
                  @input="$v.form.slug.$touch(), removeApiError('slug')"
                  @blur="$v.form.slug.$touch(), removeApiError('slug')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                  :items="pageTypes"
                  item-text="text"
                  item-value="value"
                  v-model="form.type"
                  :disabled="loadingForm"
                  :error-messages="formTypeErrors"
                  label="Typ"
                  hide-details="auto"
                  outlined
                  @change="$v.form.type.$touch(), removeApiError('type')"
                  @blur="$v.form.type.$touch(), removeApiError('type')"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.template"
                  :disabled="loadingForm"
                  :error-messages="formTemplateErrors"
                  label="Template"
                  hide-details="auto"
                  outlined
                  @input="$v.form.template.$touch(), removeApiError('template')"
                  @blur="$v.form.template.$touch(), removeApiError('template')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="form.sort"
                  :disabled="loadingForm"
                  :error-messages="formSortErrors"
                  label="Sort"
                  type="number"
                  min="1"
                  hide-details="auto"
                  outlined
                  @input="$v.form.sort.$touch(), removeApiError('sort')"
                  @blur="$v.form.sort.$touch(), removeApiError('sort')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="form.level"
                  :disabled="loadingForm"
                  :error-messages="formLevelErrors"
                  label="Level"
                  type="number"
                  min="1"
                  hide-details="auto"
                  outlined
                  @input="$v.form.level.$touch(), removeApiError('level')"
                  @blur="$v.form.level.$touch(), removeApiError('level')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                  v-model="form.show_in_nav"
                  value="1"
                  label="Pokaż w menu"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingPage" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { slugify } from '../helpers';
  import pageService from '../services/page.service';

  export default {
    name: 'PageDialog',

    props: ['pageData'],

    mixins: [validationMixin],

    validations: {
      form: {
        title: {
          required
        },
        slug: {
          required
        },
        type: {
          required
        },
        template: {
          required
        },
        sort: {
          required
        },
        level: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        title: '',
        slug: '',
        type: '',
        template: '',
        show_in_nav: '0',
        sort: null
      },
      pageTypes: [
        { text: 'Podstrona', value: 'page' },
        { text: 'Strona główna', value: 'homepage' },
        { text: 'Blog', value: 'blog' },
        { text: 'Oferta', value: 'offer' },
        { text: 'Kontakt', value: 'contact' },
      ],
      loadingPage: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formTitleErrors() {
        const errors = [];
        if (!this.$v.form.title.$dirty) return errors;
        !this.$v.form.title.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.title) errors.push(this.apiErrors.title);
        return errors;
      },
      formSlugErrors() {
        const errors = [];
        if (!this.$v.form.slug.$dirty) return errors;
        !this.$v.form.slug.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.slug) errors.push(this.apiErrors.slug);
        return errors;
      },
      formTypeErrors() {
        const errors = [];
        if (!this.$v.form.type.$dirty) return errors;
        !this.$v.form.type.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.type) errors.push(this.apiErrors.type);
        return errors;
      },
      formTemplateErrors() {
        const errors = [];
        if (!this.$v.form.template.$dirty) return errors;
        !this.$v.form.template.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.template) errors.push(this.apiErrors.template);
        return errors;
      },
      formSortErrors() {
        const errors = [];
        if (!this.$v.form.sort.$dirty) return errors;
        !this.$v.form.sort.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.sort) errors.push(this.apiErrors.sort);
        return errors;
      },
      formLevelErrors() {
        const errors = [];
        if (!this.$v.form.level.$dirty) return errors;
        !this.$v.form.level.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.level) errors.push(this.apiErrors.level);
        return errors;
      },
    },

    created() {
      const pageId = this.$route.params.pageId;
      if (pageId) {
        if (this.pageData) {
          this.form.title         = this.pageData.title;
          this.form.slug          = this.pageData.slug;
          this.form.type          = this.pageData.type;
          this.form.template      = this.pageData.template;
          this.form.show_in_nav   = this.pageData.show_in_nav;
          this.form.sort          = this.pageData.sort;
          this.form.level         = this.pageData.level;
        } else {
          this.loadingPage = true;
          this.getPage(pageId);
        }
      }
    },

    methods: {
      getPage(pageId) {
        pageService.getSingle(pageId).then(data => {
          if (data.success) {
            this.form.title         = data.data.page.title;
            this.form.slug          = data.data.page.slug;
            this.form.type          = data.data.page.type;
            this.form.template      = data.data.page.template;
            this.form.show_in_nav   = data.data.page.show_in_nav;
            this.form.sort          = data.data.page.sort;
            this.form.level         = data.data.page.level;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPage = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.pageId) {
            this.updatePage();
          } else {
            this.createPage();
          }
        }
      },

      createPage() {
        pageService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updatePage() {
        const pageId = this.$route.params.pageId;
        pageService.update(pageId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'pages' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      },

      setSlug() {
        if (this.form.slug == '') {
          this.form.slug = slugify(this.form.title);
        }
      }
    }
  }
</script>