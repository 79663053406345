import Vue from "vue";
import App from "./App.vue";
import router from './router/router';
import store from './store/index';
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
