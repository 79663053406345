<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista kategorii</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large color="primary" class="elevation-0" to="/categories/create" :disabled="loadingCategories">Dodaj kategorię</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="categoriesList"
            :sort-desc="false"
            :loading="loadingCategories"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" elevation="0" class="ma-1" @click="showCategoryDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <router-view name="categoryDialog" :categoryData="categoryData" v-on:close-dialog="closeCategoryDialog" v-on:dialog-callback="getCategories"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteCategory"></router-view>
  </v-container>
</template>

<script>
  import categoryService from '../services/category.service';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Categories',

    data: () => ({
      categoriesList: [],
      search: '',
      headers: [
        { text: 'Nazwa', value: 'name' },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingCategories: true,
      categoryData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getCategories();
    },

    methods: {
      getCategories() {
        this.loadingCategories = true;

        categoryService.getList().then(data => {
          if (data.success) {
            this.categoriesList = data.data.categories;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingCategories = false;
        });
      },

      showCategoryDialog(category) {
        this.categoryData = category;
        this.$router.push({ name: 'categoryEdit', params: { categoryId: category.id } });
      },

      closeCategoryDialog() {
        this.categoryData = null;
      },

      showDeleteDialog(category) {
        this.deleteDialogData = {
          categoryId: category.id,
          title: 'Usuwanie kategorii',
          text: 'Na pewno usunąć kategorię <strong>' + category.name + '</strong>?'
        };
        this.$router.push({ name: 'categoryDelete', params: { categoryId: category.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'categories' })
      },

      deleteCategory() {
        this.loadingDeleteDialog = true;

        categoryService.delete(this.deleteDialogData.categoryId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getCategories();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },
    }
  }
</script>