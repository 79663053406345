<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Wpis</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.title"
                  :disabled="loadingForm"
                  :error-messages="formTitleErrors"
                  label="Tytuł"
                  hide-details="auto"
                  outlined
                  @input="$v.form.title.$touch(), removeApiError('title')"
                  @blur="$v.form.title.$touch(), removeApiError('title')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  v-model="form.categories"
                  :items="categoriesList"
                  :disabled="loadingForm"
                  :error-messages="formCategoriesErrors"
                  label="Kategorie"
                  hide-details="auto"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  outlined
                  item-text="name"
                  item-value="id"
                  no-data-text="Brak dostępnych danych"
                  @input="$v.form.categories.$touch(), removeApiError('categories')"
                  @blur="$v.form.categories.$touch(), removeApiError('categories')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  v-model="form.userId"
                  :items="usersList"
                  :disabled="loadingForm"
                  :error-messages="formUserIdErrors"
                  label="Autor"
                  hide-details="auto"
                  outlined
                  item-text="fullname"
                  item-value="id"
                  no-data-text="Brak dostępnych danych"
                  @input="$v.form.userId.$touch(), removeApiError('userId')"
                  @blur="$v.form.userId.$touch(), removeApiError('userId')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="form.date"
                      :disabled="loadingForm"
                      :error-messages="formDateErrors"
                      label="Data"
                      append-icon="mdi-calendar"
                      hide-details="auto"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @input="$v.form.date.$touch(), removeApiError('date')"
                      @blur="$v.form.date.$touch(), removeApiError('date')"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="form.date"
                    no-title
                    scrollable
                    @input="menuDate = false"
                    locale="pl-pl"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingCategories || loadingUsers || loadingPost" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import postService from '../services/post.service';
  import categoryService from '../services/category.service';
  import userService from '../services/user.service';

  export default {
    name: 'PostDialog',

    props: ['postData'],

    mixins: [validationMixin],

    validations: {
      form: {
        title: {
          required
        },
        categories: {
          required
        },
        userId: {
          required
        },
        date: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        title: '',
        categories: [],
        userId: null,
        date: new Date().toISOString().slice(0, 10),
      },
      menuDate: false,
      categoriesList: [],
      loadingCategories: true,
      usersList: [],
      loadingUsers: true,
      loadingPost: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formTitleErrors() {
        const errors = [];
        if (!this.$v.form.title.$dirty) return errors;
        !this.$v.form.title.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.title) errors.push(this.apiErrors.title);
        return errors;
      },
      formCategoriesErrors() {
        const errors = [];
        if (!this.$v.form.categories.$dirty) return errors;
        !this.$v.form.categories.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.categories) errors.push(this.apiErrors.categories);
        return errors;
      },
      formUserIdErrors() {
        const errors = [];
        if (!this.$v.form.userId.$dirty) return errors;
        !this.$v.form.userId.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.userId) errors.push(this.apiErrors.userId);
        return errors;
      },
      formDateErrors() {
        const errors = [];
        if (!this.$v.form.date.$dirty) return errors;
        !this.$v.form.date.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.date) errors.push(this.apiErrors.date);
        return errors;
      },
    },

    created() {
      this.getCategories();
      this.getUsers();

      const postId = this.$route.params.postId;
      if (postId) {
        if (this.postData) {
          this.form.title       = this.postData.title;
          this.form.categories  = this.postData.categories;
          this.form.userId      = this.postData.user_id;
          this.form.date        = this.postData.date;
        } else {
          this.loadingPost = true;
          this.getPost(postId);
        }
      }
    },

    methods: {
      getCategories() {
        this.loadingCategories = true;

        categoryService.getList().then(data => {
          if (data.success) {
            this.categoriesList = [];

            data.data.categories.forEach(market => {
              this.categoriesList.push({ name: market.name, id: market.id });
            });
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingCategories = false;
        });
      },

      getUsers() {
        this.loadingUsers = true;

        userService.getConsultants().then(data => {
          if (data.success) {
            this.usersList = [];

            data.data.users.forEach(user => {
              this.usersList.push({ fullname: user.name + ' ' + user.surname, id: user.id });
            });
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingUsers = false;
        });
      },

      getPost(postId) {
        postService.getSingle(postId).then(data => {
          if (data.success) {
            this.form.title       = data.data.post.title;
            this.form.categories  = data.data.post.categories;
            this.form.userId      = data.data.post.user_id;
            this.form.date        = data.data.post.date;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPost = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.postId) {
            this.updatePost();
          } else {
            this.createPost();
          }
        }
      },

      createPost() {
        postService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updatePost() {
        const postId = this.$route.params.postId;
        postService.update(postId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'posts' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      },

    }
  }
</script>