import Login from "../views/Login";

import Dashboard from "../views/Dashboard";

import Users from "../views/Users";
import UserDialog from "../components/UserDialog";

import Consultants from "../views/Consultants";
import ConsultantTags from "../views/ConsultantTags";
import ConsultantForm from "../components/ConsultantForm";
import ConsultantTagDialog from "../components/ConsultantTagDialog";

import Pages from "../views/Pages";
import PageDetails from "../views/PageDetails";
import PageDialog from "../components/PageDialog";

import Posts from "../views/Posts";
import PostDialog from "../components/PostDialog";

import Categories from "../views/Categories";
import CategoryDialog from "../components/CategoryDialog";

import DeleteDialog from "../components/DeleteDialog";

export default [

    // ======== Default ========
    {
        path: "/",
        redirect: { name: "dashboard" }
    },

    {
        path: "/dashboard",
        component: Dashboard,
        name: "dashboard"
    },

    // ======== Account ========
    // Login
    {
        path: "/account/login",
        component: Login,
        name: "login"
    },

    // ======== Users ========
    // List
    {
        path: "/users",
        component: Users,
        name: "users",
        children: [
            // Create
            {
                path: "create",
                components: {
                    userDialog: UserDialog
                },
                name: "userCreate"
            },
            // Edit
            {
                path: ":userId/edit",
                components: {
                    userDialog: UserDialog
                },
                name: "userEdit"
            },
            // Delete
            {
                path: ":userId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "userDelete"
            }
        ]
    },

    // ======== Consultants ========
    // List
    {
        path: "/consultants",
        component: Consultants,
        name: "consultants",
        children: [
            // Delete
            {
                path: ":consultantId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "consultantDelete"
            }
        ]
    },
    // Create
    {
        path: "/consultants/create",
        component: ConsultantForm,
        name: "consultantCreate"
    },
    // Edit
    {
        path: "/consultants/:consultantId/edit",
        component: ConsultantForm,
        name: "consultantEdit"
    },
    // Tags list
    {
        path: "/consultants/tags",
        component: ConsultantTags,
        name: "consultantTags",
        children: [
            // Create
            {
                path: "create",
                components: {
                    consultantTagDialog: ConsultantTagDialog
                },
                name: "consultantTagCreate"
            },
            // Edit
            {
                path: ":consultantTagId/edit",
                components: {
                    consultantTagDialog: ConsultantTagDialog
                },
                name: "consultantTagEdit"
            },
            // Delete
            {
                path: ":consultantTagId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "consultantTagDelete"
            }
        ]
    },


    // ======== Pages ========
    // List
    {
        path: "/pages",
        component: Pages,
        name: "pages",
        children: [
            // Create
            {
                path: "create",
                components: {
                    pageDialog: PageDialog
                },
                name: "pageCreate"
            },
            // Edit
            {
                path: ":pageId/edit",
                components: {
                    pageDialog: PageDialog
                },
                name: "pageEdit"
            },
            // Delete
            {
                path: ":pageId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "pageDelete"
            }
        ]
    },
    // Details
    {
        path: "/pages/:pageId",
        component: PageDetails,
        name: "pageDetails"
    },

    // ======== Posts (blog) ========
    // List
    {
        path: "/posts",
        component: Posts,
        name: "posts",
        children: [
            // Create
            {
                path: "create",
                components: {
                    postDialog: PostDialog
                },
                name: "postCreate"
            },
            // Edit
            {
                path: ":postId/edit",
                components: {
                    postDialog: PostDialog
                },
                name: "postEdit"
            },
            // Delete
            {
                path: ":postId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "postDelete"
            }
        ]
    },

    // ======== Categories (blog) ========
    // List
    {
        path: "/categories",
        component: Categories,
        name: "categories",
        children: [
            // Create
            {
                path: "create",
                components: {
                    categoryDialog: CategoryDialog
                },
                name: "categoryCreate"
            },
            // Edit
            {
                path: ":categoryId/edit",
                components: {
                    categoryDialog: CategoryDialog
                },
                name: "categoryEdit"
            },
            // Delete
            {
                path: ":categoryId/delete",
                components: {
                    deleteDialog: DeleteDialog
                },
                name: "categoryDelete"
            }
        ]
    },

]