<template>
  <div class="fill-height">
    <v-app-bar app clipped-right color="white" class="elevation-0">
      <v-btn icon @click="goToPages()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title v-if="!loadingPage">{{ page.title }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app clipped right>
      asad
    </v-navigation-drawer>

    <div style="width: 100%;">
      <iframe :src="'https://zhr.mma.unixstorm.eu/' + page.slug" frameborder="0" style="width: 100%; height: 100%; overflow-y: auto"></iframe>
    </div>
  </div>
</template>

<script>
  import pageService from "../services/page.service";


  export default {
    name: "PageDetails",

    components: {},

    data: () => ({
      page: {
        id: null,
        title: "",
        slug: ""
      },
      loadingPage: false,
      sectionsList: [],
      loadingSections: true,
      sectionData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      const pageId = this.$route.params.pageId;
      const pageData = this.$route.params.pageData;

      if (pageData) {
        this.page.id = pageData.id;
        this.page.title = pageData.title;
        this.page.slug = pageData.slug;
      } else {
        this.loadingPage = true;
        this.getPage(pageId);
      }
    },

    methods: {
      getPage(pageId) {
        pageService.getSingle(pageId).then(data => {
          if (data.success) {
            this.page.id = data.data.page.id;
            this.page.title = data.data.page.title;
            this.page.slug = data.data.page.slug;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPage = false;
        });
      },

      goToPages() {
        this.$router.push({ name: 'pages' })
      }
    }
  }
</script>