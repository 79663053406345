<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Dashboard</h1>
        </v-col>
        <v-col cols="auto">
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Dashboard",

    data: () => ({
    })
  }
</script>