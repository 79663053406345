<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" v-if="user && $route.name !== 'pageDetails' && $route.name !== 'sectionCreate' && $route.name !== 'sectionEdit' && $route.name !== 'sectionDelete'">
      <v-list-item class="pa-4">
        <v-img :src="require('./assets/logo.svg')" max-width="150" class="mx-auto" />
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav class="px-4">
        <v-list-item to="/dashboard" link>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/pages" link>
          <v-list-item-content>
            <v-list-item-title>Strony</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Konsultanci</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/consultants" dense exact link class="px-4">
            <v-list-item-content>
              <v-list-item-title>Lista</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/consultants/tags" dense exact link class="px-4">
            <v-list-item-content>
              <v-list-item-title>Tagi</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item to="/users" link>
          <v-list-item-content>
            <v-list-item-title>Użytkownicy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/posts" link class="px-4">
            <v-list-item-content>
              <v-list-item-title>Wpisy</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/categories" link class="px-4">
            <v-list-item-content>
              <v-list-item-title>Kategorie</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-4">
          <v-btn block outlined color="error" class="elevation-0" @click="logout">
            Wyloguj się
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="white" class="elevation-0" v-if="user && $route.name !== 'pageDetails' && $route.name !== 'sectionCreate' && $route.name !== 'sectionEdit' && $route.name !== 'sectionDelete'">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <div class="fill-height" :class="{ 'px-3 px-sm-6 py-3': $route.name !== 'pageDetails' && $route.name !== 'sectionCreate' && $route.name !== 'sectionEdit' && $route.name !== 'sectionDelete' }">
        <router-view></router-view>
      </div>
    </v-main>

    <v-snackbars :objects.sync="$store.state.snackbar.items" bottom right class="snackbars">
      <template v-slot:action="{ close }">
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbars>
  </v-app>
</template>

<script>
  import VSnackbars from "v-snackbars";

  export default {
    name: "App",

    components:{
      "v-snackbars": VSnackbars
    },

    data: () => ({
      drawer: null
    }),

    computed: {
      user() {
        return this.$store.state.account.user;
      }
    },

    methods: {
      logout() {
        this.$store.dispatch("account/logout");
        this.$router.push({ name: "login" });
      }
    }
  };
</script>

<style>
  #app { background-color: #efefef; }

  .v-app-bar .v-toolbar__content { border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

  .input--search .v-input__slot { font-weight: 400; padding: 0 !important; box-shadow: none !important; }

  .v-text-field .v-text-field__details { margin-bottom: 0 !important; }

  .handle { min-width: 0 !important; padding: 0 10px !important; cursor: move; }

  /* Animation */
  .router-animation-enter-active {
    animation: coming-up 0.15s;
    opacity: 0;
    overflow: hidden;
  }

  .router-animation-leave-active {
    animation: fade-out 0.15s;
    overflow: hidden;
  }

  @keyframes coming-up {
    from {
      transform: translateY(40px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
</style>