<template>
  <v-container fluid>
    <div class="header mb-6">
      <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"></v-breadcrumbs>
    </div>

    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Konsultant</span>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  :items="users"
                  :item-text="item => `${item.name} ${item.surname} (${item.email})`"
                  item-value="id"
                  v-model="form.userId"
                  :disabled="loadingForm"
                  :error-messages="formUserIdErrors"
                  label="Użytkownik"
                  hide-details="auto"
                  outlined
                  @change="$v.form.userId.$touch(), removeApiError('userId')"
                  @blur="$v.form.userId.$touch(), removeApiError('userId')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.headline"
                  :disabled="loadingForm"
                  :error-messages="formHeadlineErrors"
                  label="Nagłówek"
                  hide-details="auto"
                  outlined
                  @input="$v.form.headline.$touch(), removeApiError('headline')"
                  @blur="$v.form.headline.$touch(), removeApiError('headline')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  v-model="form.teaser"
                  :disabled="loadingForm"
                  :error-messages="formTeaserErrors"
                  label="Teaser"
                  hide-details="auto"
                  outlined
                  rows="3"
                  @input="$v.form.teaser.$touch(), removeApiError('teaser')"
                  @blur="$v.form.teaser.$touch(), removeApiError('teaser')"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-combobox
                  :items="consultantTags"
                  item-text="name"
                  :disabled="loadingForm"
                  v-model="form.tags"
                  label="Tagi"
                  hide-selected
                  multiple
                  outlined
                  hint="Aby dodać tag, którego nie ma na liście wystarczy nacisnąć ENTER po jego wpisaniu."
                  persistent-hint
                  small-chips
                  hide-details="auto"
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <ckeditor :editor="editor" v-model="form.description" :config="editorConfig" :disabled="loadingForm"></ckeditor>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="goToConsultants">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0" color="primary" large :disabled="loadingForm" :loading="loadingForm">
            <span v-if="$route.params.consultantId">Zapisz zmiany</span>
            <span v-else>Dodaj konsultanta</span>
          </v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingUsers || loadingConsultantTags || loadingConsultant" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-container>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, integer } from 'vuelidate/lib/validators';
  import consultantService from "@/services/consultant.service";
  import userService from "@/services/user.service";
  import consultantTagService from "@/services/consultantTag.service";
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
  import { Essentials } from '@ckeditor/ckeditor5-essentials';
  import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
  import { Alignment } from '@ckeditor/ckeditor5-alignment';
  import { Link } from '@ckeditor/ckeditor5-link';
  import { List } from '@ckeditor/ckeditor5-list';
  import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
  import { Heading } from '@ckeditor/ckeditor5-heading';

  export default {
    name: "ConsultantForm",

    components: {
      ckeditor: CKEditor.component
    },

    mixins: [validationMixin],

    validations: {
      form: {
        userId: {
          required,
          integer
        },
        headline: {
          required
        },
        teaser: {
          required
        },
        description: {
        }
      }
    },

    data: () => ({
      breadcrumbsItems: [
        { text: 'Konsultanci', disabled: false, to: '/consultants', exact: true }
      ],
      form: {
        userId: null,
        headline: '',
        teaser: '',
        description: '',
        tags: ''
      },
      editor: ClassicEditor,
      editorConfig: {
        plugins: [ Essentials, Paragraph, Heading, Bold, Italic, Underline, Alignment, Link, List ],
        toolbar: {
          items: [
            'undo', 'redo', '|',
            'heading',
            'bold', 'italic', 'underline', '|',
            'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', '|',
            'link', '|',
            'bulletedList', 'numberedList',
          ]
        }
      },
      users: [],
      loadingUsers: true,
      consultantTags: [],
      loadingConsultantTags: true,
      loadingConsultant: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formUserIdErrors() {
        const errors = [];
        if (!this.$v.form.userId.$dirty) return errors;
        !this.$v.form.userId.required && errors.push('Musisz uzupełnić to pole.');
        !this.$v.form.userId.integer && errors.push('Nieprawidłowa wartość.');
        if (this.apiErrors && this.apiErrors.userId) errors.push(this.apiErrors.userId);
        return errors;
      },
      formHeadlineErrors() {
        const errors = [];
        if (!this.$v.form.headline.$dirty) return errors;
        !this.$v.form.headline.required && errors.push('Musisz uzupełnić to pole.');
        if (this.apiErrors && this.apiErrors.headline) errors.push(this.apiErrors.headline);
        return errors;
      },
      formTeaserErrors() {
        const errors = [];
        if (!this.$v.form.teaser.$dirty) return errors;
        !this.$v.form.teaser.required && errors.push('Musisz uzupełnić to pole.');
        if (this.apiErrors && this.apiErrors.teaser) errors.push(this.apiErrors.teaser);
        return errors;
      },
    },

    created() {
      this.getUsers();
      this.getConsultantTags();

      const consultantId = this.$route.params.consultantId;
      if (consultantId) {
        this.loadingConsultant = true;
        this.getConsultant(consultantId);
      } else {
        this.breadcrumbsItems.push({ text: 'Nowy konsultant', disabled: true });
      }
    },

    methods: {
      getConsultant(consultantId) {
        consultantService.getSingle(consultantId).then(data => {
          if (data.success) {
            if (this.breadcrumbsItems.length == 1) {
              this.breadcrumbsItems.push({ text: data.data.consultant.userName + ' ' + data.data.consultant.userSurname, disabled: true });
            } else {
              this.breadcrumbsItems[1].text = data.data.consultant.userName + ' ' + data.data.consultant.userSurname;
            }

            this.form.userId = data.data.consultant.userId;
            this.form.headline = data.data.consultant.headline;
            this.form.teaser = data.data.consultant.teaser;
            this.form.description = data.data.consultant.description;
            this.form.tags = data.data.consultant.tags;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingConsultant = false;
        });
      },

      getUsers() {
        this.loadingUsers = true;

        userService.getList().then(data => {
          if (data.success) {
            this.users = data.data.users;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingUsers = false;
        });
      },

      getConsultantTags() {
        this.loadingConsultantTags = true;

        consultantTagService.getList().then(data => {
          if (data.success) {
            this.consultantTags = data.data.consultantTags;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingConsultantTags = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.consultantId) {
            this.updateConsultant();
          } else {
            this.createConsultant();
          }
        }
      },

      createConsultant() {
        consultantService.create(this.form).then(data => {
          if (data.success) {
            this.$router.push({ name: 'consultants' });
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateConsultant() {
        const consultantId = this.$route.params.consultantId;

        consultantService.update(consultantId, this.form).then(data => {
          if (data.success) {
            const consultantId = this.$route.params.consultantId
            this.getConsultant(consultantId);
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      },

      goToConsultants() {
        this.$router.push({ name: 'consultants' });
      }
    }
  }
</script>

<style>
.ck-sticky-panel__content { border-color: rgba(0, 0, 0, 0.38) !important; border-top-left-radius: 4px !important; border-top-right-radius: 4px !important; }
.ck-editor__editable_inline { min-height: 200px; border-bottom-left-radius: 4px !important; border-bottom-right-radius: 4px !important; border-color: rgba(0, 0, 0, 0.38) !important; color: rgba(0, 0, 0, 0.87); }
.ck-editor__editable_inline > * { margin-bottom: 16px; }
.ck-editor__editable_inline.ck-focused:not(.ck-editor__nested-editable) { border-color: #1976d2 !important; }
</style>