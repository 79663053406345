import axios from "axios";

const apiUrl = "https://zhr.mma.unixstorm.eu/api/admin/account";

export default {

    login(userData) {
        return axios.post(apiUrl + "/login", {
            email: userData.email,
            password: userData.password
        }).then(response => {
            if (response.data.success) {
                localStorage.setItem("token", response.data.data.token);
            }
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'API connection error.'
            };
            return response;
        });
    },

    logout() {
        localStorage.removeItem("token");
    }
}